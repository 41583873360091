import React, { useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles
import { Box, Button, TextField } from "@mui/material";
import url from '../../../src/config/server-url';
// import { AdbSharp } from "@mui/icons-material";
const Admin = () => {
  const [newAdminData, setNewAdminData] = useState({
    adminname:"",
    institutename:"",
    admincontact:"",
    adminaddress:"",
    email: "",
    password: "",
    role: "admin",
  });

  const handleNewAdminInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNewAdminSave = async () => {
    try {
      const response = await axios.post(
        url+"api/users",
        newAdminData
      );
      if (response.status === 201) {
        const createdItem = response.data;
    console.log(`Successfully created Admin with ID: ${createdItem._id}`);    
         // Show success toast
         toast.success("Admin added successfully!");
    setNewAdminData({
    adminname:"", 
    institutename:"",
    admincontact:"", 
    adminaddress:"",
    email: "", 
    password: "", 
    role: "admin",
        });
      } else {
        throw new Error("Error creating Admin");
      }
    } catch (error) {
      console.error("Error creating Admin:", error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Register Admin" />
      </FlexBetween>

      <Box my="20px" sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="adminname"
              value={newAdminData.adminname}
              onChange={handleNewAdminInputChange}
              label="Enter Admin Name"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="email"
              value={newAdminData.email}
              onChange={handleNewAdminInputChange}
              label="Enter Admin Email"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="admincontact"
              value={newAdminData.admincontact}
              onChange={handleNewAdminInputChange}
              label="Enter Admin Contact"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="password"
              name="password"
              value={newAdminData.password}
              onChange={handleNewAdminInputChange}
              label="Enter Admin Password"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="institutename"
              value={newAdminData.institutename}
              onChange={handleNewAdminInputChange}
              label="Enter Institute Name"
              variant="outlined"
              fullWidth
            />
          </Grid>
  <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="adminaddress"
              value={newAdminData.adminaddress}
              onChange={handleNewAdminInputChange}
              label="Enter Admin Address"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={12}
          >
            <Button
              onClick={handleNewAdminSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Add ToastContainer to display toasts */}
      <ToastContainer position="top-right" autoClose={3000} />
    </Box>
  );
};

export default Admin;
// Super Admin File