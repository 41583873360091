import React, { useState, useEffect, useRef } from 'react'
import CustomSnackbar from 'components/CustomSnackbar'
import Grid from '@mui/material/Grid'
import Header from 'components/Header'
import axios from 'axios'
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'

import url from 'config/server-url'

const InviteStudent = () => {
  const snackbarRef = useRef()
  const [newStudent, setNewStudent] = useState({
    courseId: '',
    role: 'student',
    email: '',
    password: '',
    studentName: '',
    studentContact: '',
    studentAddress: '',
    studentStatus: 'active'
  })
  const [courseList, setCourseList] = useState([])

  const handleInputChange = e => {
    const { name, value } = e.target
    setNewStudent(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleCreateStudent = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(url + 'api/users', {
        ...newStudent,
        adminId
      })

      if (response.status === 201) {
        setNewStudent({
          courseId: '',
          role: 'student',
          email: '',
          password: '',
          studentName: '',
          studentContact: '',
          studentAddress: '',
          studentStatus: 'active'
        })
        snackbarRef.current.displaySnackBar('Student Created', 'success')
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message === 'User with this email already exists'
      ) {
        snackbarRef.current.displaySnackBar(
          error.response.data.message,
          'error'
        )
      }
    }
  }

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const getCourses = await axios.post(
        url + 'api/newCourse/getAdminCourses',
        { adminId: adminId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseList(getCourses.data)
    } catch (error) {
      console.error('Error getting courses:', error)
    }
  }

  useEffect(() => {
    getAdminCourses()
  }, [])

  return (
    <Box m='1.5rem 2.5rem'>
      <Header title='Invite Student' />

      <Box
        p='20px'
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              size='small'
              type='text'
              name='studentName'
              label='Enter Student Name'
              variant='outlined'
              value={newStudent.studentName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size='small'
              type='text'
              name='email'
              value={newStudent.email}
              onChange={handleInputChange}
              label='Enter Student Email'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size='small'
              type='password'
              name='password'
              value={newStudent.password}
              onChange={handleInputChange}
              label='Enter Student Password'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl size='small' fullWidth>
              <InputLabel id='courseName'>Select Course</InputLabel>
              <Select
                labelId='courseName'
                value={newStudent.courseId}
                label='Select Course'
                onChange={handleInputChange}
                name='courseId'
              >
                {courseList.map(course => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.courseName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size='small'
              type='text'
              name='studentContact'
              value={newStudent.studentContact}
              onChange={handleInputChange}
              label='Enter Student Contact'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              size='small'
              type='text'
              name='studentAddress'
              value={newStudent.studentAddress}
              onChange={handleInputChange}
              label='Enter Student Address'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={12}
          >
            <Button variant='contained' onClick={handleCreateStudent}>
              Invite
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default InviteStudent
