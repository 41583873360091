import { useMemo, useState, useEffect } from 'react'

import Layout from 'scenes/layout'
import Dashboard from 'scenes/dashboard'
import FileUpload from 'scenes/fileUpload/fileUpload'

import { themeSettings } from 'theme'
import { createTheme } from '@mui/material/styles'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

// Screens Imports
import RecordingStudents from 'scenes/students/recording'
import ViewQuestions from 'scenes/students/viewQuestions'
import AudioQuestion from 'scenes/students/audioQuestions'
import AudioMessage from 'scenes/students/audioMessage'
import SeeAnswers from 'scenes/students/seeAnswers'
import Admin from 'scenes/superadmin/Admin'
import SuperAdmin from 'scenes/superadmin/SuperAdmin'
import AddQuestion from 'scenes/studentActivity/addQuestion'

// Login Imports
import Login from 'scenes/login/login'
import SplashScreen from 'scenes/login/SplashScreen'

// Student Imports
import InviteStudent from 'scenes/admin/InviteStudent'
import ActiveStudents from 'scenes/students/ActiveStudents'
import ArchievedStudents from 'scenes/students/ArchivedStudents'
import DropOutStudents from 'scenes/students/DropoutStudents'

// Course Pages Imports
import NewCourse from 'scenes/courses/NewCourse'
import AddCourse from 'scenes/courses/AddCourse'
import AddChapter from 'scenes/courses/AddChapter'
import AddCategory from 'scenes/courses/AddCategory'
import AddResource from 'scenes/courses/AddResource'
import ActiveCourses from 'scenes/courses/ActiveCourses'
import UpdateCourse from 'scenes/courses/UpdateCourse'
import ArchivedCourses from 'scenes/courses/ArchivedCourses'

import MyRecordings from 'scenes/myRecordings/myRecordings.js'
import CourseMaterial from 'scenes/students/courseMaterial'

function App () {
  const theme = useMemo(() => createTheme(themeSettings()))
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  )
  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    // if (!isAuthenticated && showSplash) {
      const timer = setTimeout(() => {
        setShowSplash(false)
      }, 3000)

      return () => clearTimeout(timer)
    // }
  }, [])

  const handleAuthentication = status => {
    setIsAuthenticated(status)
    localStorage.setItem('isAuthenticated', status.toString())
  }

  const authenticatedRoutes = (
    <Route element={<Layout />}>
      <Route path='/' element={<Navigate to='/dashboard' replace />} />

      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/Register SuperAdmin' element={<SuperAdmin />} />
      <Route path='/Register Admin' element={<Admin />} />
      <Route path='/File Uploads' element={<FileUpload />} />

      {/* Students Routes */}
      <Route path='/Invite Student' element={<InviteStudent />} />
      <Route path='/Active Students' element={<ActiveStudents />} />
      <Route path='/Archived Students' element={<ArchievedStudents />} />
      <Route path='/DropOut Students' element={<DropOutStudents />} />
      <Route path='/Recording Students' element={<RecordingStudents />} />

      {/* Questions Routes */}
      {/* <Route path='/Ask Questions' element={<AddQuestion />} /> */}
      {/* <Route path='/Student Text Questions' element={<ViewQuestions />} /> */}
      {/* <Route path='/Student Audio Questions' element={<AudioQuestion />} /> */}
      {/* <Route path='/My Recordings' element={<MyRecordings />} /> */}
      {/* <Route path='/Admin Audio Message' element={<AudioMessage />} /> */}
      {/* <Route path='/See Answers' element={<SeeAnswers />} /> */}
      {/* <Route path='/Course Material' element={<CourseMaterial />} /> */}

      {/* Course Pages Routes */}
      <Route path='/New Course' element={<NewCourse />} />
      <Route path='/Update Course/:courseId' element={<UpdateCourse />} />
      <Route path='/Add Course' element={<AddCourse />} />
      <Route path='/Add Chapter' element={<AddChapter />} />
      <Route path='/Add Category' element={<AddCategory />} />
      <Route path='/Add Resource' element={<AddResource />} />
      <Route path='/Active Courses' element={<ActiveCourses />} />
      <Route path='/Archived Courses' element={<ArchivedCourses />} />
    </Route>
  )

  return (
    <div className='app'>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {!isAuthenticated && showSplash ? (
              <Route path='*' element={<SplashScreen />} />
            ) : !isAuthenticated && !showSplash ? (
              <Route
                path='/'
                element={<Login handleAuthentication={handleAuthentication} />}
              />
            ) : isAuthenticated && !showSplash ? (
              authenticatedRoutes
            ) : (
              <></>
            )}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
