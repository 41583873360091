import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Input,
  Typography,
  Grid,
  Backdrop,
  LinearProgress
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CustomSnackbar from 'components/CustomSnackbar.jsx'
import FlexBetween from 'components/FlexBetween'
import url from '../../config/server-url.jsx'
import Header from 'components/Header'
import axios from 'axios'

const AddResource = () => {
  const snackbarRef = useRef()
  const [resource, setResource] = useState({
    courseId: '',
    chapterId: '',
    categoryId: '',
    resourceName: '',
    resourceFile: null,
    fileLink: ''
  })
  const [courseList, setCourseList] = useState([])
  const [chapterList, setChapterList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [selectedFileName, setSelectedFileName] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [formError, setFormError] = useState('')

  useEffect(() => {
    getAdminCourses()
  }, [])

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const getCourses = await axios.post(
        url + 'api/newCourse/getAdminCourses',
        { adminId: adminId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseList(getCourses.data)
    } catch (error) {
      console.error('Error getting courses:', error)
    }
  }

  const handleNewResourceInputChange = async e => {
    const { name, value, files } = e.target

    if (files && files[0]) {
      setSelectedFileName(files[0].name)
      setResource(prevData => ({
        ...prevData,
        [name]: files[0]
      }))
      setFormError('') // Clear any existing error when a file is selected
    } else {
      setSelectedFileName('')
      setResource(prevData => ({
        ...prevData,
        [name]: value
      }))
    }

    if (name === 'courseId') {
      try {
        const getCourseChapters = await axios.post(
          url + 'api/newCourse/getCourseChapters',
          { courseId: value },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        setChapterList(getCourseChapters.data)

        const getCourseCategories = await axios.post(
          url + 'api/newCourse/getCourseCategories',
          { courseId: value },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        setCategoryList(getCourseCategories.data)
      } catch (error) {
        console.error('Error getting chapters or categories:', error)
      }
    }
  }

  const handleNewResourceSave = async () => {
    if (!resource.resourceFile) {
      setFormError('Please upload a file before submitting.')
      return
    }

    try {
      setIsUploading(true)
      const formData = new FormData()
      formData.append('insertedChapterId', resource.chapterId)
      formData.append('resourceName', resource.resourceName)
      formData.append('categoryId', resource.categoryId)

      const resourceFileFormData = new FormData()
      resourceFileFormData.append('resourceFile', resource.resourceFile)

      const fileLinkApi = await axios.post(
        'https://uploads.ehouse.org.uk/upload-file.php',
        resourceFileFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(progress)
          }
        }
      )

      formData.append('resourceFileLink', fileLinkApi.data)

      const newCourseApiResponse = await axios.post(
        url + 'api/newCourse',
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (newCourseApiResponse.status === 200) {
        snackbarRef.current.displaySnackBar('Resource Added', 'success')
        setResource({
          courseId: '',
          chapterId: '',
          categoryId: '',
          resourceName: '',
          resourceFile: null,
          fileLink: ''
        })
        setSelectedFileName('')
      }
    } catch (error) {
      console.error('Error creating AddResource:', error)
    } finally {
      setIsUploading(false)
      setUploadProgress(0)
    }
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='Add Resource' />
      </FlexBetween>

      <Box
        my='20px'
        sx={{
          backgroundColor: '#FFFFFF',
          padding: '10px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <Select
              fullWidth
              displayEmpty
              size='small'
              name='courseId'
              labelId='course-label'
              value={resource.courseId}
              onChange={handleNewResourceInputChange}
              inputProps={{ 'aria-label': 'Select Course' }}
            >
              <MenuItem value=''>Select Course</MenuItem>
              {courseList.map(course => (
                <MenuItem key={course._id} value={course._id}>
                  {course.courseName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <Select
              fullWidth
              displayEmpty
              size='small'
              labelId='chapter-label'
              name='chapterId'
              value={resource.chapterId}
              onChange={handleNewResourceInputChange}
              inputProps={{ 'aria-label': 'Select Chapter' }}
            >
              <MenuItem value=''>Select Chapter</MenuItem>
              {chapterList.map(chapter => (
                <MenuItem key={chapter._id} value={chapter._id}>
                  {chapter.chapterName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <Select
              fullWidth
              displayEmpty
              size='small'
              labelId='category-label'
              name='categoryId'
              value={resource.categoryId}
              onChange={handleNewResourceInputChange}
              inputProps={{ 'aria-label': 'Select Category' }}
            >
              <MenuItem value=''>Select Category</MenuItem>
              {categoryList.map(category => (
                <MenuItem key={category._id} value={category._id}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <TextField
              size='small'
              type='text'
              name='resourceName'
              value={resource.resourceName}
              onChange={handleNewResourceInputChange}
              label='Enter Resource Name'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid item container direction='row' xs={6}>
            <label htmlFor='upload-input'>
              <Input
                type='file'
                id='upload-input'
                name='resourceFile'
                onChange={handleNewResourceInputChange}
                disabled={chapterList.length === 0}
                style={{ display: 'none' }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  disabled={chapterList.length === 0}
                  variant='contained'
                  component='span'
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginRight: '5px' }}
                >
                  Upload File
                </Button>
                <Typography variant='caption' color='textSecondary'>
                  {selectedFileName
                    ? `Selected File: ${selectedFileName}`
                    : 'No file selected'}
                </Typography>
              </Box>
            </label>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={!resource.chapterId || !resource.categoryId}
              variant='contained'
              color='primary'
              onClick={handleNewResourceSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>

      {formError && (
        <Typography variant='body2' color='error'>
          {formError}
        </Typography>
      )}

      {isUploading && (
        <Backdrop
          open={isUploading}
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        >
          <Box sx={{ width: '50%' }}>
            <Typography variant='h6' color='inherit' align='center'>
              Uploading...
            </Typography>
            <LinearProgress
              variant='determinate'
              value={uploadProgress}
              sx={{ height: '10px' }}
            />
          </Box>
        </Backdrop>
      )}

      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default AddResource
