import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios'
import Grid from '@mui/material/Grid'
import Header from 'components/Header'
import url from 'config/server-url.jsx'
import FlexBetween from 'components/FlexBetween'
import CustomSnackbar from 'components/CustomSnackbar'
import LinearProgress from '@mui/material/LinearProgress'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import { useTheme } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { Box, Input, Button, TextField, Typography } from '@mui/material'

const StyledDataGrid = ({ rows, columns, height = '300px' }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
          boxShadow: 4
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none'
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.primary.main,
          color:
            theme.palette.mode === 'light'
              ? '#FFFFFF'
              : theme.palette.background.main,
          border: 'none',
          borderRadius: 0,
          boxShadow: 5
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: theme.palette.background
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: theme.palette.background,
          color: theme.palette.secondary[100],
          borderTop: 'none'
        },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
          color: `${theme.palette.secondary} !important`
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={row => row._id}
        sx={{ height }}
      />
    </Box>
  )
}

const EditCourse = () => {
  const theme = useTheme()
  const snackbarRef = useRef(null)
  const { courseId } = useParams()

  const [chapterRows, setChapterRows] = useState('')
  const [categoryRows, setCategoryRows] = useState('')
  const [resourceRows, setResourceRows] = useState('')
  const [filteredResourceRows, setFilteredResourceRows] = useState('')

  const [newCourseName, setNewCourseName] = useState('')
  const [newChapterName, setNewChapterName] = useState('')
  const [newCategoryName, setNewCategoryName] = useState('')
  const [newResourceName, setNewResourceName] = useState('')
  const [newFileLink, setNewFileLink] = useState('')

  const [uploadProgress, setUploadProgress] = useState(0)

  const [selectedChapterId, setSelectedChapterId] = useState('')
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const [selectedResourceId, setSelectedResourceId] = useState('')
  const [selectedCategoryName, setSelectedCategoryName] = useState('')
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [errors, setErrors] = useState({})

  const columns1 = [
    { field: 'chapterName', headerName: 'Chapter Name', flex: 1 },
    {
      field: 'editChapter',
      headerName: 'Edit Chapter',
      renderCell: params => (
        <Button
          color='success'
          variant='contained'
          onClick={() => {
            setNewChapterName(params.row.chapterName)
            setSelectedChapterId(params.row._id)
          }}
        >
          Edit
        </Button>
      )
    }
  ]
  const columns2 = [
    { field: 'categoryName', headerName: 'Category Name', flex: 1 },
    {
      field: 'selectCategory',
      headerName: 'Select Category',
      renderCell: params => (
        <Button
          variant='contained'
          onClick={() =>
            handleCategorySelect(params.row._id, params.row.categoryName)
          }
        >
          Select
        </Button>
      )
    },
    {
      field: 'editCategory',
      headerName: 'Edit Category',
      renderCell: params => (
        <Button
          color='success'
          variant='contained'
          onClick={() => {
            setNewCategoryName(params.row.categoryName)
          }}
        >
          Edit
        </Button>
      )
    }
  ]

  const columns3 = [
    { field: 'resourceName', headerName: 'Resource Name', flex: 1 },
    {
      field: 'selectResource',
      headerName: 'Select Resource',
      sortable: false,
      renderCell: params => (
        <Button
          color='success'
          variant='contained'
          onClick={() => {
            setNewResourceName(params.row.resourceName)
            setSelectedResourceId(params.row._id)
          }}
        >
          Edit
        </Button>
      )
    }
  ]

  useEffect(() => {
    if (courseId) {
      fetchCourseDetails(courseId)
    }
  }, [courseId])

  const fetchCourseDetails = async id => {
    try {
      const response = await axios.post(
        url + 'api/newCourse/getCourseDetails',
        { courseId: id }
      )
      const courseData = response.data

      setNewCourseName(courseData.course.courseName)
      localStorage.setItem('courseId', courseData.course._id)

      if (courseData.chapters) {
        const chapterRows = courseData.chapters.map(chapter => ({
          _id: chapter._id,
          chapterName: chapter.chapterName
        }))
        setChapterRows(chapterRows)
      }

      if (courseData.categories) {
        const categoryRows = courseData.categories.map(category => ({
          _id: category._id,
          categoryName: category.categoryName
        }))

        console.log('categoryRows = ')
        console.log(categoryRows)

        setCategoryRows(categoryRows)
      }
      console.log('categoryRows after setting = ')
      console.log(categoryRows)

      if (courseData.resources) {
        const resourceRowsFetched = courseData.resources.map(resource => ({
          _id: resource._id,
          resourceName: resource.resourceName,
          categoryId: resource.categoryId
        }))

        console.log('resourceRows Initial = ')
        console.log(resourceRowsFetched)

        setResourceRows(resourceRowsFetched)
      }
    } catch (error) {
      console.error('Error fetching course details:', error)
    }
  }

  const handleEditCourseName = async () => {
    if (!newCourseName.trim()) {
      snackbarRef.current.displaySnackBar(
        'Course name cannot be empty!',
        'error'
      )
      return
    }

    try {
      const response = await axios.put(`${url}api/newCourse/updateCourseName`, {
        courseId: courseId,
        courseName: newCourseName
      })
      snackbarRef.current.displaySnackBar(
        'Course name updated successfully!',
        'success'
      )
    } catch (error) {
      snackbarRef.current.displaySnackBar(
        'Error updating course name!',
        'error'
      )
    }
  }

  const handleEditChapter = async () => {
    if (!newChapterName.trim()) {
      snackbarRef.current.displaySnackBar(
        'Chapter name cannot be empty!',
        'error'
      )
      return
    }

    try {
      const response = await axios.put(`${url}api/newCourse/updateChapter`, {
        chapterId: selectedChapterId,
        chapterName: newChapterName
      })
      console.log('Chapter updated:', response.data)

      setChapterRows(prevRows =>
        prevRows.map(chapter =>
          chapter._id === selectedChapterId
            ? { ...chapter, chapterName: newChapterName }
            : chapter
        )
      )

      snackbarRef.current.displaySnackBar(
        'Chapter updated successfully!',
        'success'
      )

      setNewChapterName('')
    } catch (error) {
      console.error('Error updating chapter:', error)
      snackbarRef.current.displaySnackBar('Error updating chapter!', 'error')
    }
  }

  const handleEditCategory = async categoryId => {
    if (!newCategoryName.trim()) {
      snackbarRef.current.displaySnackBar(
        'Category name cannot be empty!',
        'error'
      )
      return
    }

    try {
      const response = await axios.put(`${url}api/newCourse/updateCategory`, {
        categoryId: categoryId,
        categoryName: newCategoryName
      })

      setCategoryRows(prevRows =>
        prevRows.map(category =>
          category._id === categoryId
            ? { ...category, categoryName: newCategoryName }
            : category
        )
      )

      setNewCategoryName('')
      setSelectedCategoryId('')

      snackbarRef.current.displaySnackBar(
        'Category updated successfully!',
        'success'
      )
    } catch (error) {
      console.error('Error updating category:', error)
      snackbarRef.current.displaySnackBar('Error updating category!', 'error')
    }
  }

  const handleEditResource = async () => {
    if (!selectedFile && !newResourceName) {
      snackbarRef.current.displaySnackBar('No changes to update', 'warning')
      return
    }

    try {
      setIsUploading(true)
      let updatedFileLink = newFileLink

      if (selectedFile) {
        const resourceFileFormData = new FormData()
        resourceFileFormData.append('resourceFile', selectedFile)

        // Use the original filename when uploading
        const originalFilename = newFileLink.split('/').pop()
        resourceFileFormData.append('filename', originalFilename)

        const fileLinkApi = await axios.post(
          'https://uploads.ehouse.org.uk/upload-file.php',
          resourceFileFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadProgress(progress)
            }
          }
        )

        updatedFileLink = fileLinkApi.data
      }

      const response = await axios.put(`${url}api/newCourse/updateResource`, {
        resourceId: selectedResourceId,
        resourceName: newResourceName,
        fileLink: updatedFileLink
      })
      console.log('Resource updated:', response.data)

      setFilteredResourceRows(prevRows =>
        prevRows.map(resource =>
          resource._id === selectedResourceId
            ? { ...resource, resourceName: newResourceName }
            : resource
        )
      )

      setNewResourceName('')
      setSelectedFileName('')
      setSelectedFile(null)
      setNewFileLink('')

      snackbarRef.current.displaySnackBar(
        'Resource updated successfully!',
        'success'
      )
    } catch (error) {
      console.error('Error updating resource:', error)
      snackbarRef.current.displaySnackBar('Error updating resource!', 'error')
    } finally {
      setIsUploading(false)
      setUploadProgress(0)
    }
  }

  const handleCategorySelect = (categoryId, categoryName) => {
    setSelectedCategoryId(categoryId)
    setSelectedCategoryName(categoryName)
    
    const filteredResources = resourceRows.filter(
      resource => resource.categoryId === categoryId
    )

    console.log('Filtered Resources = ')
    console.log(filteredResources)
    setFilteredResourceRows(filteredResources)
  }

  const handleNewResourceInputChange = event => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFileName(file.name)
      setSelectedFile(file)
    }
  }

  return (
    <Box p='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='Add New Course' />
      </FlexBetween>
      <Box
        my='30px'
        p='30px'
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
        }}
      >
        <Typography
          variant='h4'
          align='center'
          sx={{ my: '20px', fontWeight: 'bold' }}
        >
          Edit Course Name
        </Typography>
        <TextField
          size='small'
          type='text'
          name='courseName'
          label='Course Name'
          variant='outlined'
          value={newCourseName}
          onChange={e => setNewCourseName(e.target.value)}
          fullWidth
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
        >
          <Button
            color='success'
            variant='contained'
            onClick={handleEditCourseName}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Box
        my='30px'
        p='30px'
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
        }}
      >
        <Grid container direction='row' spacing={3}>
          <Grid item xs={5.5}>
            <Typography
              variant='h4'
              align='center'
              sx={{ my: '20px', fontWeight: 'bold' }}
            >
              Edit Chapter Names
            </Typography>
            <Box
              height='100%'
              display='flex'
              flexDirection='column'
              alignItems='stretch'
            >
              <TextField
                size='small'
                type='text'
                name='chapterName'
                variant='outlined'
                label='Enter Chapter Name'
                value={newChapterName}
                error={!!errors.chapterName}
                helperText={errors.chapterName}
                onChange={e => setNewChapterName(e.target.value)}
                fullWidth
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                {newChapterName === '' ? (
                  <Typography variant='h6' color='error'>
                    Select a chapter to edit
                  </Typography>
                ) : (
                  <Button
                    color='success'
                    variant='contained'
                    onClick={handleEditChapter}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6.5}>
            <Typography
              variant='h4'
              align='center'
              sx={{ my: '20px', fontWeight: 'bold' }}
            >
              Course Chapters
            </Typography>
            <StyledDataGrid rows={chapterRows} columns={columns1} />
          </Grid>
        </Grid>
      </Box>
      <Box
        my='30px'
        p='30px'
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
        }}
      >
        <Grid container direction='row' spacing={3}>
          <Grid item xs={5.5}>
            <Typography
              variant='h4'
              align='center'
              sx={{ my: '20px', fontWeight: 'bold' }}
            >
              Edit Category
            </Typography>
            <Box
              display='flex'
              flexDirection='column'
              height='100%'
              alignItems='stretch'
            >
              <TextField
                size='small'
                type='text'
                variant='outlined'
                name='categoryName'
                label='Enter Category'
                value={newCategoryName}
                error={!!errors.categoryName}
                helperText={errors.categoryName}
                onChange={e => setNewCategoryName(e.target.value)}
                fullWidth
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                {newCategoryName === '' ? (
                  <Typography variant='h6' color='error'>
                    Select a category to edit
                  </Typography>
                ) : (
                  <Button
                    color='success'
                    variant='contained'
                    onClick={handleEditCategory}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6.5}>
            <Typography
              variant='h4'
              align='center'
              sx={{ my: '20px', fontWeight: 'bold' }}
            >
              Course Categories
            </Typography>
            <StyledDataGrid rows={categoryRows} columns={columns2} />
          </Grid>
        </Grid>
      </Box>
      <Box
        my='30px'
        p='30px'
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
        }}
      >
        {selectedCategoryName !== '' && (
          <Typography
            variant='h4'
            align='center'
            sx={{ mb: '20px', fontWeight: 'bold' }}
          >
            Selected Category: {selectedCategoryName}
          </Typography>
        )}
        <Grid container direction='row' spacing={3}>
          <Grid item xs={5.5}>
            <Typography
              variant='h4'
              align='center'
              sx={{ my: '20px', fontWeight: 'bold' }}
            >
              Edit Resources
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                gap: '15px'
              }}
            >
              <TextField
                size='small'
                type='text'
                name='resourceName'
                value={newResourceName}
                onChange={e => setNewResourceName(e.target.value)}
                error={!!errors.resourceName}
                helperText={errors.resourceName}
                label='Enter Resource Title'
                variant='outlined'
                fullWidth
              />
              <label htmlFor='upload-input'>
                <Input
                  type='file'
                  id='upload-input'
                  name='resourceFile'
                  style={{ display: 'none' }}
                  error={!!errors.resourceFile}
                  onChange={handleNewResourceInputChange}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    padding: '10px',
                    gap: '10px'
                  }}
                >
                  <Button
                    variant='contained'
                    component='span'
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload File
                  </Button>
                  <Typography variant='caption' color='textSecondary'>
                    {selectedFileName
                      ? `Selected File: ${selectedFileName}`
                      : 'No file selected'}
                  </Typography>
                </Box>
              </label>
              {isUploading && (
                <LinearProgress variant='determinate' value={uploadProgress} />
              )}
              {selectedCategoryId === '' ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='h6' color='error'>
                    Select a category to edit resource
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color='success'
                    variant='contained'
                    onClick={handleEditResource}
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={6.5}>
            <Typography
              variant='h4'
              align='center'
              sx={{ my: '20px', fontWeight: 'bold' }}
            >
              Category Resources
            </Typography>
            <StyledDataGrid rows={filteredResourceRows} columns={columns3} />
          </Grid>
        </Grid>
      </Box>

      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default EditCourse