import React from 'react'
import { Box, Typography } from '@mui/material'

const DocumentViewer = ({ documents }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      sx={{ marginTop: '30px' }}
    >
      {documents.map((res, index) => (
        <Box
          key={index}
          marginBottom='20px'
          width='100%'
          textAlign='left'
          onClick={() => window.open(res.url, '_blank')}
          style={{ cursor: 'pointer' }}
        >
          <Typography
            variant='body1'
            component='span'
            sx={{ color: 'black', textDecoration: 'none', marginRight: '10px' }}
          >
            {index + 1}.
          </Typography>
          <Typography
            variant='body1'
            component='span'
            sx={{ color: 'blue', textDecoration: 'none' }}
          >
            {res.name}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default DocumentViewer
