import React, { useState, useEffect } from 'react'
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Divider
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRightOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined
} from '@mui/icons-material'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { useLocation, useNavigate } from 'react-router-dom'
import FlexBetween from './FlexBetween'
import HomeIcon from '@mui/icons-material/Home'
import AddIcon from '@mui/icons-material/Add'
import LockIcon from '@mui/icons-material/Lock'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import CategoryIcon from '@mui/icons-material/Category'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const navItems = [
  {
    text: 'Dashboard',
    icon: <HomeIcon />
  },
  {
    text: 'Super Admin Actions',
    icon: <Groups2Outlined />,
    role: 'superAdmin',
    subItems: [
      { text: 'Register SuperAdmin', icon: <Groups2Outlined /> },
      { text: 'Register Admin', icon: <ReceiptLongOutlined /> }
    ]
  },
  // {
  //   text: 'Student Activity',
  //   icon: <ReceiptLongOutlined />,
  //   role: 'student',
  //   subItems: [
  //     { text: 'Course Material', icon: <MenuBookIcon /> },
  //     { text: 'My Recordings', icon: <ReceiptLongOutlined /> }
  //   ]
  // },
  // {
  //   text: 'Question & Answers',
  //   icon: <PublicOutlined />,
  //   role: 'student',
  //   subItems: [
  //     { text: 'Ask Questions', icon: <PublicOutlined /> }, // Changed icon
  //     { text: 'See Answers', icon: <ReceiptLongOutlined /> }
  //   ]
  // },
  // {
  //   text: 'Administrative Section',
  //   icon: <PublicOutlined />,
  //   role: 'student',
  //   subItems: [{ text: 'Audio Messages', icon: <PublicOutlined /> }] // Changed icon
  // },
  {
    text: 'Profile',
    icon: <Groups2Outlined />,
    role: 'student',
    subItems: [
      { text: 'Profile', icon: <Groups2Outlined /> },
      { text: 'Update Profile', icon: <ReceiptLongOutlined /> },
      { text: 'Update Password', icon: <LockIcon /> } // Changed icon
    ]
  },
  {
    text: 'Students',
    icon: <Groups2Outlined />,
    role: 'admin',
    subItems: [
      { text: 'Invite Student', icon: <PersonAddIcon /> },
      { text: 'Active Students', icon: <Groups2Outlined /> },
      { text: 'Archived Students', icon: <ReceiptLongOutlined /> },
      { text: 'Dropout Students', icon: <PersonOffIcon /> }
    ]
  },
  {
    text: 'Courses',
    icon: <MenuBookIcon />,
    role: 'admin',
    subItems: [
      { text: 'Add Course', icon: <AddIcon /> },
      { text: 'Add Chapter', icon: <AddIcon /> },
      { text: 'Add Category', icon: <CategoryIcon /> }, // Changed icon
      { text: 'Add Resource', icon: <AttachFileIcon /> }, // Changed icon
      { text: 'Active Courses', icon: <CheckCircleIcon /> }, // Changed icon
      { text: 'Archived Courses', icon: <ReceiptLongOutlined /> },
    ]
  }
  // {
  //   text: 'Questions',
  //   icon: null,
  //   role: 'admin',
  //   subItems: [
  //     { text: 'Student Text Questions', icon: <PublicOutlined /> },
  //     { text: 'Student Audio Questions', icon: <Groups2Outlined /> },
  //     { text: 'Admin Audio Message', icon: <PublicOutlined /> }
  //   ]
  // }
]

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile
}) => {
  const { pathname } = useLocation()
  const [active, setActive] = useState('')
  const navigate = useNavigate()
  const theme = useTheme()
  const [expandedItems, setExpandedItems] = useState([])
  const [filteredNavItems, setFilteredNavItems] = useState([])

  useEffect(() => {
    setActive(pathname.substring(1))
  }, [pathname])

  useEffect(() => {
    const role = localStorage.getItem('role')
    const filteredItems = navItems.filter(item => {
      if (item.text === 'Dashboard') {
        return true
      }
      if (item.role === role) {
        return true // Show the tab if unique_id matches
      }
      return false // Hide the tab if no condition matches
    })
    setFilteredNavItems(filteredItems)
  }, [])

  const handleSubItemClick = subText => {
    navigate(`/${subText.toLowerCase()}`)
    setActive(subText.toLowerCase())
  }

  const handleMainItemClick = (mainText, subItems) => {
    if (subItems) {
      const isActive = expandedItems.includes(mainText.toLowerCase())
      if (isActive) {
        setExpandedItems(
          expandedItems.filter(item => item !== mainText.toLowerCase())
        )
      } else {
        setExpandedItems([...expandedItems, mainText.toLowerCase()])
      }
    } else {
      navigate(`/${mainText.toLowerCase()}`)
      setActive(mainText.toLowerCase())
    }
  }

  return (
    <Box component='nav'>
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant='persistent'
          anchor='left'
          sx={{
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.special,
              boxSizing: 'border-box',
              borderWidth: isNonMobile ? 0 : '2px',
              width: drawerWidth
            }
          }}
        >
          <Box width='100%'>
            <Box m='1.5rem 2rem 2rem 3rem'>
              <FlexBetween color='#FFFFFF'>
                <Box display='flex' alignItems='left' gap='0.5rem'>
                  <Typography variant='h4' fontWeight='bold'>
                    EduSupplements
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List sx={{ paddingLeft: 0 }}>
              {' '}
              {filteredNavItems.map(({ text, icon, subItems }, mainIndex) => {
                const lcText = text.toLowerCase()
                const isMainItemActive = active === lcText
                const isSubItemsExpanded = expandedItems.includes(lcText)
                return (
                  <React.Fragment key={text}>
                    <ListItem>
                      <ListItemButton
                        onClick={() => handleMainItemClick(text, subItems)}
                        sx={{
                          backgroundColor: isMainItemActive
                            ? theme.palette.secondary[300]
                            : 'transparent',
                          color: isMainItemActive
                            ? theme.palette.primary
                            : theme.palette.secondary[100],
                          paddingLeft: 0 // Set paddingLeft to 0 to align main items to the left
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: '1rem',
                            color: '#FFFFFF'
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            color: '#FFFFFF'
                          }}
                          primary={text}
                        />
                        {subItems && (
                          <ChevronRightOutlined
                            sx={{
                              ml: 'auto',
                              color: '#FFFFFF',
                              transform: isSubItemsExpanded
                                ? 'rotate(90deg)'
                                : 'none'
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                    {mainIndex !== filteredNavItems.length - 1 && (
                      <Divider
                        variant='middle'
                        sx={{ backgroundColor: '#FFFFFF70' }}
                      />
                    )}
                    {/* <Divider variant='middle' sx={{ backgroundColor: '#FFFFFF70'}} /> */}
                    {subItems && isSubItemsExpanded && (
                      <List sx={{ paddingLeft: 0 }}>
                        {subItems.map(({ text: subText, icon: subIcon }) => (
                          <React.Fragment key={subText}>
                            <ListItem disablePadding>
                              <ListItemButton
                                onClick={() => handleSubItemClick(subText)}
                                sx={{
                                  backgroundColor:
                                    active === subText.toLowerCase()
                                      ? theme.palette.secondary[300]
                                      : 'transparent',
                                  color:
                                    active === subText.toLowerCase()
                                      ? theme.palette.primary
                                      : theme.palette.secondary[100],
                                  paddingLeft: 0
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    ml: '1rem',
                                    color: '#FFFFFF'
                                  }}
                                >
                                  {subIcon}
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    color: '#FFFFFF'
                                  }}
                                  primary={subText}
                                />
                              </ListItemButton>
                            </ListItem>
                          </React.Fragment>
                        ))}
                        {mainIndex !== filteredNavItems.length - 1 && (
                          <Divider
                            variant='middle'
                            sx={{ backgroundColor: '#FFFFFF70' }}
                          />
                        )}
                      </List>
                    )}
                  </React.Fragment>
                )
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar