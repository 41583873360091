import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.png'
import eduCover from '../../assets/edu-cover.jpg'

import { Box, Button, keyframes } from '@mui/material'

const blink = keyframes`
  0% { opacity: 1; }
  25% { opacity: 0.5; }
  50% { opacity: 0; }
  75% { opacity: 0.5; }
  100% { opacity: 1; }
`

const SplashScreen = () => {
  const navigate = useNavigate()

  const handleLoginClick = () => {
    navigate('/login')
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '150px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: '10%',
            height: '50%',
            backgroundColor: '#BC0000'
          }}
        />
        <Box
          alt='Logo'
          src={logo}
          component='img'
          sx={{
            width: '20%',
            height: '100%',
            objectFit: 'contain'
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            height: '50%',
            backgroundColor: '#BC0000'
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: '80%',
            height: '300',
            display: 'flex',
            maxWidth: '800px',
            overflow: 'hidden',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center'
          }}
        >
          <Box
            src={eduCover}
            component='img'
            alt='Educational Supplements'
            sx={{
              width: '80%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '70px',
          backgroundColor: '#1F7EC2'
        }}
      />
    </Box>
  )
}

export default SplashScreen
