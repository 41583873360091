import React, { useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import axios from "axios";
import Grid from "@mui/material/Grid";
import url from '../../../src/config/server-url';
import { Box, Button, TextField } from "@mui/material";


const SuperAdmin = () => {
  const [newSuperAdminData, setNewSuperAdminData] = useState({
    superadminname:"", 
    superadmincontact:"",
    superadminaddress:"",
    email: "",
    password: "",
    role: "superAdmin",
  });

  const handleNewSuperAdminInputChange = (e) => {
    const { name, value } = e.target;
    setNewSuperAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNewSuperAdminSave = async () => {
    try {
      const newAdminData = {
        ...newSuperAdminData,
        role: "superAdmin",
      };
      const response = await axios.post(
        url+`api/users`,
        newAdminData
      );
      if (response.status === 201) {
        const createdItem = response.data;
        console.log(
          `Successfully created SuperAdmin with ID: ${createdItem._id}`
        );
        // Reset the form fields
        setNewSuperAdminData({
          superadminname:"", 
          superadmincontact:"",
          superadminaddress:"",
          email: "",
          password: "",
          role: "superAdmin",
        });
      } else {
        throw new Error("Error creating SuperAdmin");
      }
    } catch (error) {
      console.error("Error creating SuperAdmin:", error);
    }
  };
  
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Register Super Admin" />
      </FlexBetween>

      <Box my="20px" sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="superadminname"
              value={newSuperAdminData.superadminname}
              onChange={handleNewSuperAdminInputChange}
              label="Enter Super Admin Name"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="superadmincontact"
              value={newSuperAdminData.superadmincontact}
              onChange={handleNewSuperAdminInputChange}
              label="Enter Super Admin Contact"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="superadminaddress"
              value={newSuperAdminData.superadminaddress}
              onChange={handleNewSuperAdminInputChange}
              label="Enter Super Admin Address"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="text"
              name="email"
              value={newSuperAdminData.email}
              onChange={handleNewSuperAdminInputChange}
              label="Enter Super Admin Email"
              variant="outlined"
              fullWidth
            />
          </Grid>


          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="password"
              name="password"
              value={newSuperAdminData.password}
              onChange={handleNewSuperAdminInputChange}
              label="Enter Super Admin Password"
              variant="outlined"
              fullWidth
            />
          </Grid>

        
         

         

         

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={12}
          >
            <Button
              onClick={handleNewSuperAdminSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SuperAdmin;
