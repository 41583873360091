import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Header from "components/Header";
import { Box, TextField, Button } from "@mui/material";
import axios from "axios";
import FlexBetween from "components/FlexBetween";
import url from '../../../src/config/server-url';
const FileUploadForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
 
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axios.post(url+"api/fileUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("File uploaded successfully!");

      // Clear the selected file
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Upload File" />
      </FlexBetween>

      <Box my="20px" sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={6}
          >
            <TextField size='small'
              type="file"
              name="selectedFile"
              onChange={handleFileChange}
              variant="outlined"
              fullWidth
            />
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "lightgreen",
                color:"black",
                marginTop: "10px",
              }}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FileUploadForm;
