import React, { useState } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import ReactPlayer from 'react-player'

const thumbnails = [
  'https://via.placeholder.com/150?text=Video+1',
  'https://via.placeholder.com/150?text=Video+2',
  'https://via.placeholder.com/150?text=Video+3'
]

const VideoPlayerWithPlaylist = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

  const handleVideoSelection = index => {
    setCurrentVideoIndex(index)
  }

  return (
    <Box display='flex' sx={{ paddingTop: '20px' }}>
      <Box flex={2} marginRight={2}>
        <Typography variant='h4' gutterBottom>
          {videos[currentVideoIndex].name}
        </Typography>
        <ReactPlayer
          url={videos[currentVideoIndex].url}
          controls
          width='100%'
          height='auto'
        />
      </Box>
      <Box flex={1}>
        <Typography variant='h4' gutterBottom>
          Playlist
        </Typography>
        <List>
          {videos.map((res, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleVideoSelection(index)}
              selected={index === currentVideoIndex}
            >
              <ListItemAvatar>
                <Avatar
                  src={thumbnails[index]}
                  alt={`Thumbnail for video ${index + 1}`}
                />
              </ListItemAvatar>
              <ListItemText primary={res.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default VideoPlayerWithPlaylist
