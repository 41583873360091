import React, { useState } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'

const AudioPlayerWithPlaylist = ({ audioFiles }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0)

  const handleTrackChange = index => {
    setCurrentTrackIndex(index)
  }

  return (
    <Box display='flex' sx={{ paddingTop: '20px' }}>
      <Box flex={2} marginRight={2}>
        <Typography variant='h4' gutterBottom>
          {audioFiles[currentTrackIndex].name}
        </Typography>
        <audio
          controls
          src={audioFiles[currentTrackIndex].url}
          style={{ width: '100%' }}
        >
          Your browser does not support the audio element.
        </audio>
      </Box>
      <Box flex={1}>
        <Typography variant='h4' gutterBottom>
          Playlist
        </Typography>
        <List>
          {audioFiles.map((res, index) => (
            <ListItem
              button
              selected={index === currentTrackIndex}
              onClick={() => handleTrackChange(index)}
              key={index}
            >
              <ListItemAvatar>
                <Avatar>
                  <MusicNoteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={res.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default AudioPlayerWithPlaylist
