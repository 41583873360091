import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { FormControl, InputLabel } from '@mui/material'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Input,
  Typography
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import axios from 'axios'
import url from '../../config/server-url.jsx'
import Header from 'components/Header'
import FlexBetween from 'components/FlexBetween'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const NewCourse = () => {
  const theme = useTheme()

  // Snack Bar
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('success')

  // Layout Changes
  const [submittedCourseName, setSubmittedCourseName] = useState('')

  const [selectedChapterId, setSelectedChapterId] = useState('')
  const [selectedChapterName, setSelectedChapterName] = useState('')

  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const [selectedCategoryName, setSelectedCategoryName] = useState('')
  
  const [courseAdded, setCourseAdded] = useState(false)

   const [selectedFileName, setSelectedFileName] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [chapterRows, setChapterRows] = useState('')
  const [categoryRows, setCategoryRows] = useState('')
  const [resourceRows, setResourceRows] = useState('')
  const [errors, setErrors] = useState({})
  const [apiSuccess, setApiSuccess] = useState(false)

  const columns1 = [
    { field: 'chapterName', headerName: 'Chapter Name', flex: 1 },
    {
      field: 'selectChapter',
      headerName: 'Select Chapter',
      sortable: false,
      renderCell: params => (
        <Button
          variant='contained'
          onClick={() => {
            setSelectedChapterId(params.row._id)
            setSelectedChapterName(params.row.chapterName)

            // Need to make sure that this request is only fired if
            // the categories for a particular chapter is added and not everytime
            getChapterCategories(params.row._id)
          }}
        >
          Select
        </Button>
      )
    }
  ]
  const columns2 = [
    { field: 'categoryName', headerName: 'Category Name', flex: 1 },
    { field: 'fileType', headerName: 'File Type', flex: 1 },
    {
      field: 'selectCategory',
      headerName: 'Select Category',
      sortable: false,
      renderCell: params => (
        <Button
          variant='contained'
          onClick={() => {
            setSelectedCategoryId(params.row._id)
            setSelectedCategoryName(params.row.categoryName)

            console.log('Category Name Selected = ')
            console.log(selectedCategoryName)

            // Need to make sure that this request is only fired if
            // the categories for a particular chapter is added and not everytime
            getCategoryResources(params.row._id)
          }}
        >
          Select
        </Button>
      )
    }
  ]
  const columns3 = [
    { field: 'resourceName', headerName: 'Resource Name', flex: 1 }
  ]

  const [newCourse, setnewCourse] = useState({
    courseName: '',
    adminId: '',
    chapterName: '',
    categoryName: '',
    fileType: '',
    maxFileSize: '',
    resourceName: '',
    resourceFile: null
  })

  const getChapterCategories = async chapterId => {
    try {
      const getChapterCategories = await axios.post(
        url + 'api/newCourse/getChapterCategories',
        { chapterId: chapterId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      setCategoryRows(getChapterCategories.data)
    } catch (error) {
      console.error('Error getting categories:', error)
    }
  }

  // continue from here connect this function with select category button and add selected category typography
  const getCategoryResources = async categoryId => {
    try {
      const categoryResources = await axios.post(
        url + 'api/newCourse/getCategoryResources',
        { categoryId: categoryId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      setResourceRows(categoryResources.data)
    } catch (error) {
      console.error('Error getting categories:', error)
    }
  }

  // Snack Bar Functions
  const handleClick = (newMessage, severity) => {
    setMessage(newMessage)
    setSeverity(severity)
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleNewCourseInputChange = e => {
    const { name, value } = e.target
    setnewCourse(prevData => ({
      ...prevData,
      [name]: value
    }))

    setErrors(prevState => ({
      ...prevState,
      [name]: ''
    }))
  }

  const handleNewResourceInputChange = e => {
    const { name, value, files } = e.target

    if (files && files[0]) {
      setSelectedFileName(files[0].name)
      setnewCourse(prevData => ({
        ...prevData,
        [name]: files[0]
      }))
    } else {
      setSelectedFileName('')
      setnewCourse(prevData => ({
        ...prevData,
        [name]: value
      }))
    }
  }

  const resetForm = () => {
    setnewCourse({
      courseName: '',
      chapterName: '',
      categoryName: '',
      fileType: '',
      maxFileSize: '',
      resourceName: '',
      resourceFile: null
    })
  }

  const courseDataApi = async formData => {
    console.log('Form Data Passed To Api Function')
    console.log(formData)

    const newCourseApiResponse = await axios.post(
      url + 'api/newCourse',
      formData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return newCourseApiResponse
  }

  const submitCourse = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const formData = new FormData()

      formData.append('adminId', adminId)
      formData.append('courseName', newCourse.courseName)

      const newCourseApiResponse = await courseDataApi(formData)

      if (newCourseApiResponse.status === 200) {
        setSubmittedCourseName(newCourse.courseName)
        setCourseAdded(true)
        setApiSuccess(true)

        localStorage.setItem('courseId', newCourseApiResponse.data.courseId)
        handleClick('Course Added', 'success')

        resetForm()
      }
    } catch (error) {
      console.error('Error = ', error)
    }
  }

  const submitChapter = async () => {
    try {
      const formData = new FormData()

      formData.append('courseId', localStorage.getItem('courseId'))
      formData.append('chapterName', newCourse.chapterName)

      const newCourseApiResponse = await courseDataApi(formData)

      if (newCourseApiResponse.data.chapters) {
        const chapters = newCourseApiResponse.data.chapters

        const rows = chapters.map(chapterName => ({
          _id: chapterName._id,
          chapterName: chapterName.chapterName
        }))
        setChapterRows(rows)
        setApiSuccess(true)
        handleClick('Chapter Added', 'success')

        resetForm()
      }
    } catch (error) {
      console.error('Error = ', error)
    }
  }

  const submitCategory = async () => {
    try {
      const formData = new FormData()
      formData.append('chapterId', selectedChapterId)
      formData.append('categoryName', newCourse.categoryName)
      formData.append('fileType', newCourse.fileType)

      const newCourseApiResponse = await courseDataApi(formData)

      if (newCourseApiResponse.data.categories) {
        const categories = newCourseApiResponse.data.categories

        const rows = categories.map(category => ({
          _id: category._id,
          categoryName: category.categoryName,
          fileType: category.fileType
        }))
        setCategoryRows(rows)
        setApiSuccess(true)
        handleClick('Category Added', 'success')

        resetForm()
      }
    } catch (error) {
      console.error('Error = ', error)
    }
  }

  const submitResource = async () => {
    try {
      const formData = new FormData()
      formData.append('categoryId', selectedCategoryId)
      formData.append('resourceName', newCourse.resourceName)

      const resourceFileFormData = new FormData()
      resourceFileFormData.append('resourceFile', newCourse.resourceFile)

      const fileLinkApi = await axios.post(
        'https://uploads.ehouse.org.uk/upload-file.php',
        resourceFileFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(progress)
          }
        }
      )

      formData.append('resourceFileLink', fileLinkApi.data)
      const newCourseApiResponse = await courseDataApi(formData)

      if (newCourseApiResponse.data.resources) {
        const resources = newCourseApiResponse.data.resources

        const rows = resources.map(resource => ({
          _id: resource._id,
          resourceName: resource.resourceName
        }))
        setResourceRows(rows)
        setApiSuccess(true)
        handleClick('Resource Added', 'success')

        resetForm()
      }
    } catch (error) {
      console.error('Error = ', error)
    }
  }

  const validateCourseName = () => {
    let tempErrors = {}

    if (!newCourse.courseName) {
      tempErrors.courseName = 'Course Name is required'
    }

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0
  }

  const validateChapterName = () => {
    let tempErrors = {}

    if (!newCourse.chapterName) {
      tempErrors.chapterName = 'Chapter Name is required'
    }

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0
  }

  const validateCategoryDetails = () => {
    let tempErrors = {}

    if (!newCourse.categoryName) {
      tempErrors.categoryName = 'Category Name is required'
    }

    if (!newCourse.fileType || newCourse.fileType === '') {
      tempErrors.fileType = 'Please select a file type'
    }

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0
  }

  const validateResourceDetails = () => {
    let tempErrors = {}

    if (!newCourse.resourceName) {
      tempErrors.resourceName = 'Resource Name is required'
    }

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0
  }

  const handleAddCourseName = async () => {
    if (!validateCourseName()) return
    await submitCourse()
  }

  const handleAddChapterName = async () => {
    if (!validateChapterName()) return
    await submitChapter()
  }

  const handleAddCategoryDetails = async () => {
    if (!validateCategoryDetails()) return
    await submitCategory()
  }

  const handleAddResourceDetails = async () => {
    if (!validateResourceDetails()) return
    await submitResource()
  }

  return (
    <Box p='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='Add New Course' />
      </FlexBetween>

        <Box
          my='30px'
          sx={{
            margin: 'auto',
            padding: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              {!courseAdded ? (
                <>
                  <Typography
                    variant='h4'
                    align='center'
                    sx={{ my: '20px', fontWeight: 'bold' }}
                  >
                    Add Course Name
                  </Typography>
                  <TextField
                    size='small'
                    type='text'
                    name='courseName'
                    label='Course Name'
                    variant='outlined'
                    value={newCourse.courseName}
                    onChange={handleNewCourseInputChange}
                    error={!!errors.courseName}
                    helperText={errors.courseName}
                    fullWidth
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: '20px'
                    }}
                  >
                    <Button variant='contained' onClick={handleAddCourseName}>
                      Add
                    </Button>
                  </Box>
                </>
              ) : (
                <Typography
                  variant='h4'
                  align='center'
                  sx={{ my: '20px', fontWeight: 'bold' }}
                >
                  Course Added: {submittedCourseName}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box
          my='30px'
          p='30px'
          sx={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
          }}
        >
          <Grid container direction='row' spacing={3}>
            <Grid item xs={5.5}>
              <Typography
                variant='h4'
                align='center'
                sx={{ my: '20px', fontWeight: 'bold' }}
              >
                Add Chapter Names
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                height='100%'
                alignItems='stretch'
              >
                <TextField
                  size='small'
                  type='text'
                  name='chapterName'
                  variant='outlined'
                  label='Enter Chapter Name'
                  value={newCourse.chapterName}
                  onChange={handleNewCourseInputChange}
                  error={!!errors.chapterName}
                  helperText={errors.chapterName}
                  disabled={!apiSuccess}
                  fullWidth
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <Button
                    variant='contained'
                    onClick={handleAddChapterName}
                    disabled={!apiSuccess}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6.5}>
              <Typography
                variant='h4'
                align='center'
                sx={{ my: '20px', fontWeight: 'bold' }}
              >
                Added Chapters
              </Typography>
              <Box
                overflowY='auto'
                gridColumn='span 12'
                gridRow='span 3'
                my='20px'
                sx={{
                  '& .MuiDataGrid-root': {
                    border: 'none',
                    boxShadow: 4
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: theme.palette.primary.main,
                    color:
                      theme.palette.mode === 'light'
                        ? '#FFFFFF'
                        : theme.palette.background.main,
                    border: 'none',
                    borderRadius: 0,
                    boxShadow: 5
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    backgroundColor: theme.palette.background
                  },
                  '& .MuiDataGrid-footerContainer': {
                    backgroundColor: theme.palette.background,
                    color: theme.palette.secondary[100],
                    borderTop: 'none'
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                    color: `${theme.palette.secondary} !important`
                  }
                }}
              >
                <DataGrid
                  rows={chapterRows}
                  columns={columns1}
                  getRowId={row => row._id}
                  sx={{ height: '200px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          my='30px'
          p='30px'
          sx={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
          }}
        >
          {selectedChapterName !== '' && (
            <Typography
              variant='h4'
              align='center'
              sx={{ mb: '20px', fontWeight: 'bold' }}
            >
              Selected Chapter: {selectedChapterName}
            </Typography>
          )}
          <Grid container direction='row' spacing={3}>
            <Grid item xs={5.5}>
              <Typography
                variant='h4'
                align='center'
                sx={{ my: '20px', fontWeight: 'bold' }}
              >
                Add Category And File Type
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                height='100%'
                alignItems='stretch'
              >
                <TextField
                  size='small'
                  type='text'
                  name='categoryName'
                  value={newCourse.categoryName}
                  onChange={handleNewCourseInputChange}
                  error={!!errors.categoryName}
                  helperText={errors.categoryName}
                  label='Enter Category'
                  variant='outlined'
                  disabled={chapterRows.length === 0}
                  fullWidth
                />

                <FormControl
                  sx={{ marginTop: '15px' }}
                  fullWidth
                  variant='outlined'
                >
                  <InputLabel id='filetype-label'>Select File Type</InputLabel>
                  <Select
                    labelId='filetype-label'
                    id='filetype'
                    name='fileType'
                    value={newCourse.fileType}
                    onChange={handleNewCourseInputChange}
                    disabled={chapterRows.length === 0}
                    label='Select File Type'
                  >
                    <MenuItem value='Document'>Document</MenuItem>
                    <MenuItem value='Image'>Image</MenuItem>
                    <MenuItem value='Video'>Video</MenuItem>
                  </Select>
                </FormControl>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  {selectedChapterId === '' ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h5' color='secondary'>
                        Select a chapter to add category
                      </Typography>
                    </Box>
                  ) : (
                    <Button
                      variant='contained'
                      onClick={handleAddCategoryDetails}
                      disabled={chapterRows.length === 0}
                    >
                      Add
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6.5}>
              <Typography
                variant='h4'
                align='center'
                sx={{ my: '20px', fontWeight: 'bold' }}
              >
                Added Categories
              </Typography>
              <Box
                sx={{
                  '& .MuiDataGrid-root': {
                    border: 'none',
                    boxShadow: 4
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: theme.palette.primary.main,
                    color:
                      theme.palette.mode === 'light'
                        ? '#FFFFFF'
                        : theme.palette.background.main,
                    border: 'none',
                    borderRadius: 0,
                    boxShadow: 5
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    backgroundColor: theme.palette.background
                  },
                  '& .MuiDataGrid-footerContainer': {
                    backgroundColor: theme.palette.background,
                    color: theme.palette.secondary[100],
                    borderTop: 'none'
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                    color: `${theme.palette.secondary} !important`
                  }
                }}
              >
                <DataGrid
                  rows={categoryRows}
                  columns={columns2}
                  getRowId={row => row._id}
                  sx={{ height: '200px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          my='30px'
          p='30px'
          sx={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
          }}
        >
          {selectedCategoryName !== '' && (
            <Typography
              variant='h4'
              align='center'
              sx={{ mb: '20px', fontWeight: 'bold' }}
            >
              Selected Category: {selectedCategoryName}
            </Typography>
          )}
          <Grid container direction='row' spacing={3}>
            <Grid item xs={5.5}>
              <Typography
                variant='h4'
                align='center'
                sx={{ my: '20px', fontWeight: 'bold' }}
              >
                Upload Resources
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  gap: '15px'
                }}
              >
                <TextField
                  size='small'
                  type='text'
                  name='resourceName'
                  value={newCourse.resourceName}
                  onChange={handleNewCourseInputChange}
                  error={!!errors.resourceName}
                  helperText={errors.resourceName}
                  label='Enter Resource Title'
                  variant='outlined'
                  disabled={categoryRows.length === 0}
                  fullWidth
                />
                <label htmlFor='upload-input'>
                  <Input
                    name='resourceFile'
                    id='upload-input'
                    type='file'
                    onChange={handleNewResourceInputChange}
                    disabled={categoryRows.length === 0}
                    error={!!errors.resourceFile}
                    helperText={errors.resourceFile}
                    style={{ display: 'none' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      padding: '10px',
                      gap: '10px'
                    }}
                  >
                    <Button
                      variant='contained'
                      component='span'
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload File
                    </Button>
                    <Typography variant='caption' color='textSecondary'>
                      {selectedFileName
                        ? `Selected File: ${selectedFileName}`
                        : 'No file selected'}
                    </Typography>
                  </Box>
                </label>
                {selectedCategoryId === '' ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h5' color='secondary'>
                      Select a category to add resource
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant='contained'
                      onClick={handleAddResourceDetails}
                      disabled={selectedCategoryId === ''}
                    >
                      Add
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={6.5}>
              <Typography
                variant='h4'
                align='center'
                sx={{ my: '20px', fontWeight: 'bold' }}
              >
                Uploaded Resources
              </Typography>
              <Box
                overflowY='auto'
                sx={{
                  '& .MuiDataGrid-root': {
                    border: 'none',
                    boxShadow: 4
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: 'none'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: theme.palette.primary.main,
                    color:
                      theme.palette.mode === 'light'
                        ? '#FFFFFF'
                        : theme.palette.background.main,
                    border: 'none',
                    borderRadius: 0,
                    boxShadow: 5
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    backgroundColor: theme.palette.background
                  },
                  '& .MuiDataGrid-footerContainer': {
                    backgroundColor: theme.palette.background,
                    color: theme.palette.secondary[100],
                    borderTop: 'none'
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                    color: `${theme.palette.secondary} !important`
                  }
                }}
              >
                <DataGrid
                  rows={resourceRows}
                  columns={columns3}
                  getRowId={row => row._id}
                  sx={{ height: '200px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={severity} variant='filled'>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default NewCourse