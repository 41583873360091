import React from 'react'
import { Box } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

const ImageCarousel = ({ imageUrls }) => {
  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
  
        <Carousel navButtonsAlwaysVisible>
          {imageUrls.map((res, index) => (
            <Box
              key={index}
              component='img'
              src={res.url}
              alt={`Slide ${index + 1}`}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          ))}
        </Carousel>
    </Box>
  )
}

export default ImageCarousel