import React, { useState, useEffect, useRef } from 'react'
import { Box, Button, TextField, Select, MenuItem } from '@mui/material'
import CustomSnackbar from 'components/CustomSnackbar.jsx'
import FlexBetween from 'components/FlexBetween'
import url from '../../config/server-url.jsx'
import Header from 'components/Header'
import Grid from '@mui/material/Grid'
import axios from 'axios'

const AddCategory = () => {
  const snackbarRef = useRef()

  const [category, setCategory] = useState({
    courseId: '',
    categoryName: '',
    fileType: '',
    maxFileSize: ''
  })
  const [courseList, setCourseList] = useState([])

  useEffect(() => {
    getAdminCourses()
  }, [])

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const getCourses = await axios.post(
        url + 'api/newCourse/getAdminCourses',
        { adminId: adminId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseList(getCourses.data)
    } catch (error) {
      console.error('Error getting courses:', error)
    }
  }

  const handleNewCategoryInputChange = e => {
    const { name, value } = e.target
    setCategory(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleNewCategorySave = async () => {
    try {
      const formData = new FormData()
      formData.append('courseId', category.courseId)
      formData.append('categoryName', category.categoryName)
      formData.append('fileType', category.fileType)
      formData.append('maxFileSize', category.maxFileSize)

      const newCourseApiResponse = await axios.post(
        url + 'api/newCourse',
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (newCourseApiResponse.status === 200) {
        snackbarRef.current.displaySnackBar('Category Added', 'success')

        setCategory({
          courseId: '',
          categoryName: '',
          fileType: '',
          maxFileSize: ''
        })
      }
    } catch (error) {
      console.error('Error creating AddCategory:', error)
    }
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='Add Category' />
      </FlexBetween>

      <Box
        my='20px'
        sx={{
          backgroundColor: '#FFFFFF',
          padding: '10px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)'
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <Select
              fullWidth
              displayEmpty
              size='small'
              name='courseId'
              value={category.courseId}
              onChange={handleNewCategoryInputChange}
              inputProps={{ 'aria-label': 'Select Course' }}
            >
              <MenuItem value='' disabled>
                Select Course
              </MenuItem>
              {courseList.map(course => (
                <MenuItem key={course._id} value={course._id}>
                  {course.courseName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <TextField
              size='small'
              type='text'
              name='categoryName'
              value={category.categoryName}
              onChange={handleNewCategoryInputChange}
              label='Enter Category Name'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <Select
              fullWidth
              size='small'
              labelId='filetype-label'
              name='fileType'
              value={category.fileType}
              onChange={handleNewCategoryInputChange}
              inputProps={{ 'aria-label': 'Pick File Type' }}
              displayEmpty
            >
              <MenuItem value='' disabled>
                Pick File Type
              </MenuItem>
              <MenuItem value='Document'>Document</MenuItem>
              <MenuItem value='Image'>Image</MenuItem>
              <MenuItem value='Video'>Video</MenuItem>
              <MenuItem value='Audio'>Audio</MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={6}
          >
            <TextField
              size='small'
              type='text'
              name='maxFileSize'
              value={category.maxFileSize}
              onChange={handleNewCategoryInputChange}
              label='Enter Max File Size'
              variant='outlined'
              fullWidth
            />
          </Grid>

          <Grid
            item
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            xs={12}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handleNewCategorySave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CustomSnackbar ref={snackbarRef} />
    </Box>
  )
}

export default AddCategory
